<template>
  <v-container>
    <v-row v-if="loading">
      <v-col>
        <h3 class="text-center">
          <v-progress-circular indeterminate class="ml-3" color="primary" />
          Please be patient, this may take a while.
        </h3>
      </v-col>
    </v-row>

    <v-alert
      v-model="error"
      transition="fade-transition"
      color="error"
      style="z-index: 2; position: fixed; right: 15px"
    >
      {{ errorMessage }}
      <v-icon class="ml-5" @click="error = false">mdi-close</v-icon>
    </v-alert>

    <v-alert
      v-show="submitting"
      transition="fade-transition"
      color="primary"
      style="z-index: 2; position: fixed; right: 15px"
    >
      <v-progress-circular indeterminate class="ml-3" color="white" />
      Submitting property...
    </v-alert>

    <v-alert
      v-model="submitted"
      transition="fade-transition"
      color="success"
      style="z-index: 2; position: fixed; right: 15px"
    >
      Successfully Submitted!
      <v-icon @click="submitted = false"> mdi-close </v-icon>
    </v-alert>

    <v-row v-if="!loading" justify="center">
      <v-col xs="12" lg="10">
        <v-expansion-panels v-model="panels" flat multiple>
          <!-- property -->
          <v-expansion-panel class="bordered mb-5">
            <v-expansion-panel-header>
              <div>
                Property Information
                <v-icon class="ml-2">mdi-home-city-outline</v-icon>
                <v-btn
                  icon
                  title="Edit Property Information"
                  class="ml-2"
                  @click="
                    $router.push(
                      `/dashboard/admin/property/update?property_id=${id}`
                    )
                  "
                >
                  <v-icon> mdi-pencil-outline </v-icon>
                </v-btn>
              </div>
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <v-simple-table dense>
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td :width="250">Address</td>
                      <td>
                        {{ prop.address.street_number + "," }}
                        {{ prop.address.street_name + "," }}
                        {{ prop.address.street_type + "," }}
                        {{ prop.address.street_direction + "," }}
                        {{ prop.address.unit + "," }}
                        {{ prop.address.postal_code + "," }}
                        {{ prop.address.city + "," }}
                        {{ prop.address.province }}
                      </td>
                    </tr>

                    <tr>
                      <td>Type</td>
                      <td>{{ prop.prop_style.property_type }}</td>
                    </tr>

                    <tr>
                      <td>Comments</td>
                      <td>{{ prop.comments }}</td>
                    </tr>

                    <tr>
                      <td>Purchase Price</td>
                      <td>{{ prop.purchase_price | currency }}</td>
                    </tr>

                    <tr>
                      <td>Purchase Date</td>
                      <td>{{ prop.purchase_date | formatDate }}</td>
                    </tr>

                    <tr>
                      <td>MLS Listing Number</td>
                      <td>{{ prop.mls_listing_number }}</td>
                    </tr>

                    <tr>
                      <td>Tenure</td>
                      <td>{{ prop.tenure }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>

              <!-- valuation -->
              <v-expansion-panel class="bordered mt-5">
                <v-expansion-panel-header> Valuation </v-expansion-panel-header>

                <v-expansion-panel-content>
                  <p
                    v-if="prop.prop_vals.length > 0"
                    class="warning--text mt-5"
                  >
                    <b>DIRECTIONS</b> : Click the row to <b>EDIT</b> and use
                    checkbox if you wish to <b>DELETE</b> one/multiple row.
                  </p>

                  <v-data-table
                    :headers="property_valuation_header"
                    item-key="id"
                    :items="prop.prop_vals"
                    :items-per-page="5"
                    @click:row="handle_updating_valuation"
                    v-model="selected_valuations"
                    show-select
                  >
                    <template v-slot:item.value="{ item }">
                      {{ String(item.value) | formatPrice }}
                    </template>

                    <template v-slot:item.date="{ item }">
                      {{ item.date | formatDate }}
                    </template>
                  </v-data-table>

                  <v-btn
                    v-if="selected_valuations.length > 0"
                    @click="handle_delete_valuation"
                    color="error"
                    class="mt-2"
                  >
                    delete
                  </v-btn>

                  <AddValuation
                    v-if="is_adding_valuation || is_updating_valuation"
                    class="mt-5"
                    :is_adding_valuation="is_adding_valuation"
                    :is_updating_valuation="is_updating_valuation"
                    :update_prop_val="new_prop_val"
                    :prop_vals="prop.prop_vals"
                    @handle_submit="handle_submit_valuation"
                    @handle_cancel="
                      is_adding_valuation = false;
                      is_updating_valuation = false;
                    "
                  />

                  <v-row class="mt-5">
                    <v-col>
                      <v-btn
                        v-if="!is_adding_valuation"
                        @click="is_adding_valuation = true"
                      >
                        add valuation
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- existing mortgage -->
              <v-expansion-panel class="bordered mt-5">
                <v-expansion-panel-header>
                  Existing Mortgage
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                  <p
                    v-if="prop.prop_morts.length > 0"
                    class="warning--text mt-5"
                  >
                    <b>DIRECTIONS</b> : Click the row to <b>EDIT</b> and use
                    checkbox if you wish to <b>DELETE</b> one/multiple row.
                  </p>

                  <v-data-table
                    :headers="existing_mortgage_header"
                    item-key="id"
                    :items="prop.prop_morts"
                    :items-per-page="5"
                    :sort-by="['position']"
                    @click:row="handle_updating_mortgage"
                    v-model="selected_mortgages"
                    show-select
                  >
                    <template v-slot:item.balance_remaining="{ item }">
                      {{ String(item.balance_remaining) | formatPrice }}
                    </template>
                  </v-data-table>

                  <v-btn
                    v-if="selected_mortgages.length > 0"
                    @click="handle_delete_mortgage"
                    color="error"
                    class="mt-2"
                  >
                    delete
                  </v-btn>

                  <AddExistingMort
                    v-if="is_adding_mortgage || is_updating_mortgage"
                    class="mt-5"
                    :is_adding_mortgage="is_adding_mortgage"
                    :is_updating_mortgage="is_updating_mortgage"
                    :update_prop_mort="new_prop_mort"
                    :prop_morts="prop.prop_morts"
                    @handle_submit="handle_submit_mortgage"
                    @handle_cancel="
                      is_adding_mortgage = false;
                      is_updating_mortgage = false;
                    "
                  />

                  <v-row class="mt-5">
                    <v-col>
                      <v-btn
                        v-if="!is_adding_mortgage"
                        @click="is_adding_mortgage = true"
                      >
                        add mortgage
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <!-- associated customers -->
          <v-expansion-panel class="bordered mb-5">
            <v-expansion-panel-header>
              <div>
                Associated Customers
                <v-icon class="ml-2">mdi-account-multiple</v-icon>
              </div>
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <v-text-field
                v-model="search_customer"
                append-icon="mdi-magnify"
                label="Search"
                class="mb-2"
                single-line
                hide-details
              />

              <v-data-table
                v-model="selected_customer"
                :headers="header_associated_customer"
                :items="prop.customers"
                :items-per-page="5"
                :search="search_customer"
                item-key="id"
                @click:row="handle_click_associated_customer($event)"
                show-select
                dense
              />

              <v-btn
                v-if="selected_customer.length > 0"
                color="red"
                class="mt-5"
                @click="handle_unassociate_customer"
              >
                unassociate
              </v-btn>

              <div class="mt-5">
                <v-btn
                  v-if="!associating_customer"
                  @click="associating_customer = true"
                >
                  associate customer
                </v-btn>
              </div>

              <div class="mt-5">
                <AddExistingCustomer
                  v-if="associating_customer"
                  type="associate_to_property"
                  :associated_customers="prop.customers"
                  :customer_suggestions="customer_suggestions"
                  :unassociated_customers="unassociated_customers"
                  @close="associating_customer = false"
                  @handle_save_customer="handle_save_customer"
                />
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <!-- associated deals -->
          <v-expansion-panel class="bordered mb-5">
            <v-expansion-panel-header>
              Associated Deals
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <v-text-field
                v-model="search_deal"
                append-icon="mdi-magnify"
                label="Search"
                class="mb-2"
                single-line
                hide-details
              />

              <v-data-table
                @click:row="handle_click_deal"
                v-model="selected_deal"
                :headers="header_deals"
                :search="search_deal"
                :items="prop.int_props"
                :items-per-page="-1"
                item-key="id"
              >
                <template v-slot:[`item.province`]="{ item }">
                  {{ item.province | capitalize }}
                </template>
                <template v-slot:[`item.amountRequested`]="{ item }">
                  {{ item.amountRequested | currency }}
                </template>
                <template v-slot:[`item.status`]="{ item }">
                  {{ item.status | capitalize_first_letter }}
                </template>
                <template v-slot:[`item.loan_to_value`]="{ item }">
                  {{ item.ltv }}%
                </template>
                <template v-slot:[`item.deal_summary_status`]="{ item }">
                  {{
                    item.deal_summaries.some((el) => el.status === "active")
                      ? "Active"
                      : "Inactive"
                  }}
                </template>
              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import API from "@/plugins/API";
import filt from "@/plugins/filters";
import AddValuation from "@/components/property/Add_Valuation.vue";
import AddExistingMort from "@/components/property/Add_Existing_Mort.vue";
import AddExistingCustomer from "@/components/customer/AddExistingCustomer.vue";

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
  },

  components: {
    AddValuation,
    AddExistingMort,
    AddExistingCustomer,
  },

  data() {
    return {
      loading: true,
      submitted: false,
      submitting: false,
      error: false,
      errorMessage: "",
      search: "",

      search_deal: "",
      selected_deal: [],

      search_customer: "",
      selected_customer: [],
      customer_suggestions: [],
      unassociated_customers: [],

      panels: [0],

      prop: {},

      // for adding new valuation
      is_adding_valuation: false,
      is_updating_valuation: false,
      new_prop_val: {},
      selected_valuations: [],

      // for adding new mortgage
      is_adding_mortgage: false,
      is_updating_mortgage: false,
      new_prop_mort: {},
      selected_mortgages: [],

      // for adding new customer
      associating_customer: false,

      // for adding new deal
      associating_deal: false,

      // table headers

      property_valuation_header: [
        { text: "Type", value: "type" },
        { text: "Value", value: "value" },
        { text: "Source", value: "source" },
        { text: "Date", value: "date" },
        { text: "Final Value", value: "final_value" },
      ],

      existing_mortgage_header: [
        { text: "Position", value: "position" },
        { text: "Lender", value: "lender" },
        { text: "Balance Remaining", value: "balance_remaining" },
        { text: "Pay Off", value: "payoff" },
      ],

      header_associated_customer: [
        { text: "First Name", value: "first_name" },
        { text: "Last Name", value: "last_name" },
        { text: "Email", value: "email" },
        { text: "Phone number", value: "cell_phone_number" },
        { text: "", value: "remove" },
      ],

      header_deals: [
        {
          text: "Public Name",
          align: "start",
          filterable: true,
          value: "deal.public_name",
        },
        {
          text: "Internal Name",
          align: "start",
          filterable: true,
          value: "deal.internal_name",
        },

        {
          text: "Province",
          align: "start",
          filterable: true,
          value: "deal.province",
        },
        {
          text: "Status",
          align: "start",
          filterable: true,
          value: "deal.status",
        },
        {
          text: "Deal Summary Status",
          align: "start",
          filterable: true,
          value: "deal.deal_summary_status",
        },

        {
          text: "Stage",
          align: "start",
          filterable: true,
          value: "deal.kbn_stg.stage_name",
        },
      ],
    };
  },

  methods: {
    handle_submit_valuation(value) {
      if (this.is_adding_valuation) {
        this.handle_save_valuation(value);
      }

      if (this.is_updating_valuation) {
        this.handle_update_valuation(value);
      }
    },

    async handle_save_valuation(new_prop_val) {
      try {
        this.submitting = true;

        const response = await API().post(
          `api/internal-admin/property/create_property_valuation`,
          {
            user_id: this.$store.getters["Auth/getAuthUser"].id,
            prop_id: this.id,
            prop_val: new_prop_val,
          }
        );

        console.log(response);

        if (response.status === 201) {
          this.submitting = false;
          this.submitted = true;

          this.prop.prop_vals.push(response.data);

          this.is_adding_valuation = false;
          this.is_updating_valuation = false;
        } else {
          this.submitting = false;
          this.error = true;
          this.errorMessage = "Something went wrong. Please try again.";
        }
      } catch (error) {
        console.log(error);
        this.error = true;
        this.errorMessage = error.message;
        this.submitting = false;
      }
    },

    async handle_update_valuation(new_prop_val) {
      try {
        this.submitting = true;

        const response = await API().patch(
          `api/internal-admin/property/update_property_valuation`,
          {
            prop_val: new_prop_val,
          }
        );

        console.log(response);

        if (response.status === 201) {
          this.submitting = false;
          this.submitted = true;

          this.prop.prop_vals[
            this.prop.prop_vals.findIndex((x) => x.id === new_prop_val.id)
          ] = response.data;

          this.is_adding_valuation = false;
          this.is_updating_valuation = false;
        } else {
          this.submitting = false;
          this.error = true;
          this.errorMessage = "Something went wrong. Please try again.";
        }
      } catch (error) {
        console.log(error);
        this.error = true;
        this.errorMessage = error.message;
        this.submitting = false;
      }
    },

    handle_updating_valuation(value) {
      if (this.is_adding_valuation) {
        alert(
          "You are currently adding valuation. Please finish it first before updating."
        );
        return;
      } else {
        this.is_updating_valuation = true;

        this.new_prop_val = value;
      }
    },

    handle_delete_valuation() {
      if (window.confirm("Are you sure you want to delete this?")) {
        this.selected_valuations.forEach(async (item) => {
          try {
            const response = await API().post(
              `api/internal-admin/property/delete_prop_val`,
              {
                prop_val_id: item.id,
              }
            );

            console.log(response);

            if (response.status === 201) {
              this.prop.prop_vals.splice(this.prop.prop_vals.indexOf(item), 1);
            } else {
              this.error = true;
              this.errorMessage = "Something went wrong. Please try again.";
            }
          } catch (error) {
            this.error = true;
            this.errorMessage = error;
          }
        });

        this.selected_valuations = [];
      } else {
        console.log("deleting canceled");
        this.selected_valuations = [];
      }
    },

    handle_submit_mortgage(value) {
      if (this.is_adding_mortgage) {
        this.handle_save_mortgage(value);
      }

      if (this.is_updating_mortgage) {
        this.handle_update_mortgage(value);
      }
    },

    async handle_save_mortgage(new_prop_mort) {
      try {
        const response = await API().post(
          `api/internal-admin/property/create_property_mortgage`,
          {
            prop_cost_id: this.prop.prop_cost.id,
            prop_id: this.id,
            prop_mort: new_prop_mort,
          }
        );

        console.log(response);

        if (response.status === 201) {
          this.submitting = false;
          this.submitted = true;

          this.prop.prop_morts.push(response.data);

          this.is_adding_mortgage = false;
          this.is_updating_mortgage = false;
        } else {
          this.submitting = false;
          this.error = true;
          this.errorMessage = "Something went wrong. Please try again.";
        }
      } catch (error) {
        console.log(error);
        this.error = true;
        this.errorMessage = error.message;
        this.submitting = false;
      }
    },

    async handle_update_mortgage(new_prop_mort) {
      try {
        this.submitting = true;

        const response = await API().patch(
          `api/internal-admin/property/update_property_mortgage`,
          {
            prop_id: this.id,
            prop_mort: new_prop_mort,
            user_id: this.$store.getters["Auth/getAuthUser"].id,
          }
        );

        console.log(response);

        if (response.status === 201) {
          this.submitting = false;
          this.submitted = true;

          this.prop.prop_morts[
            this.prop.prop_morts.findIndex((x) => x.id === new_prop_mort.id)
          ] = new_prop_mort;

          this.is_adding_mortgage = false;
          this.is_updating_mortgage = false;
        } else {
          this.submitting = false;
          this.error = true;
          this.errorMessage = "Something went wrong. Please try again.";
        }
      } catch (error) {
        console.log(error);
        this.error = true;
        this.errorMessage = error.message;
        this.submitting = false;
      }
    },

    handle_updating_mortgage(value) {
      if (this.is_adding_mortgage) {
        alert(
          "You are currently adding existing mortgage. Please finish it first before updating."
        );
        return;
      } else {
        this.is_updating_mortgage = true;

        this.new_prop_mort = value;
      }
    },

    handle_delete_mortgage() {
      if (window.confirm("Are you sure you want to delete this?")) {
        this.selected_mortgages.forEach(async (item) => {
          try {
            const response = await API().post(
              `api/internal-admin/property/delete_prop_mort`,
              {
                prop_mort_id: item.id,
              }
            );

            console.log(response);

            if (response.status === 201) {
              this.prop.prop_morts.splice(
                this.prop.prop_morts.indexOf(item),
                1
              );
            } else {
              this.error = true;
              this.errorMessage = "Something went wrong. Please try again.";
            }
          } catch (error) {
            this.error = true;
            this.errorMessage = error;
          }
        });

        this.selected_mortgages = [];
      } else {
        console.log("deleting canceled");
        this.selected_mortgages = [];
      }
    },

    handle_click_associated_customer(value) {
      this.$router.push(`/dashboard/admin/customer/read/${value.id}`);
    },

    async handle_save_customer(new_customer) {
      try {
        const response = await API().post(
          `api/internal-admin/property/associate_customer_to_property`,
          {
            prop_id: this.id,
            customer_id: new_customer.id,
            user_id: this.$store.getters["Auth/getAuthUser"].id,
          }
        );

        console.log(response);

        if (response.status === 201) {
          this.prop.customers.push(new_customer);
          this.read_property();
          this.read_customer_suggestions();
          this.associating_customer = false;
        } else {
          this.error = true;
          this.errorMessage = "Something went wrong. Please try again.";
        }
      } catch (error) {
        console.log(error);
        this.error = true;
        this.errorMessage = error.message;
      }
    },

    async handle_unassociate_customer() {
      if (window.confirm("Are you sure you want to unassociate this?")) {
        try {
          const response = await API().patch(
            `api/internal-admin/property/unassociate_customer_to_property`,
            {
              prop_id: Number(this.id),
              customer_ids: this.selected_customer.map((item) => item.id),
            }
          );

          console.log(response);

          if (response.status === 201) {
            this.selected_customer.forEach((item) => {
              this.prop.customers.splice(this.prop.customers.indexOf(item), 1);
            });

            this.prop.int_props = response.data.int_props;

            this.selected_customer = [];
            this.read_customer_suggestions();
          } else {
            this.error = true;
            this.errorMessage = "Something went wrong. Please try again.";
          }
        } catch (error) {
          console.log("Error Message:", error.response.data.message);
          this.error = true;
          this.errorMessage = "Error Message: " + error.response.data.message;
        }
      } else {
        console.log("unassociating canceled");
        this.selected_customer = [];
      }
    },

    async read_customer_suggestions() {
      try {
        if (this.prop.customers.length > 0) {
          const response = await API().post(
            `api/internal-admin/property/customer_suggestions`,
            {
              customers: this.prop.customers,
            }
          );

          this.customer_suggestions = response.data;
        }
      } catch (error) {
        console.log(error);
      }
    },

    async read_all_customer() {
      try {
        const response = await API().get(
          `api/internal-admin/customer/read_all_customers`
        );

        this.unassociated_customers = response.data;

        if (response) {
          for (const item of this.unassociated_customers) {
            if (this.prop.customers.some((el) => el.id === item.id)) {
              console.log("already associated current customer");
            } else {
              if (item.id === Number(this.$route.query.customer_id)) {
                this.handle_save_customer(item);
              }
            }
          }

          if (this.prop.customers.length > 0) {
            this.read_customer_suggestions();
          }
        }
        // associate the current customer to prop.customers
      } catch (error) {
        console.log(error);
      }
    },

    handle_click_deal(value) {
      this.$router.push(`/dashboard/admin/deal/read/${value.deal.id}`);
    },

    async handle_save_deal(value) {
      try {
        const response = await API().post(
          `api/internal-admin/property/associate_deal_to_property`,
          {
            prop_id: this.id,
            deal_id: value.id,
          }
        );

        console.log(response.data);

        if (response.status === 201) {
          this.prop.int_props.push(response.data);
        } else {
          this.error = true;
          this.errorMessage = "Something went wrong. Please try again.";
        }
      } catch (error) {
        console.log(error);
        this.error = true;
        this.errorMessage = error.message;
      }
    },

    async handle_unassociate_deals() {
      if (window.confirm("Are you sure you want to unassociate this?")) {
        try {
          const response = await API().post(
            `api/internal-admin/property/unassociate_deal_to_property`,
            {
              int_prop_ids: this.selected_deal.map((item) => item.id),
            }
          );

          console.log(response);

          if (response.status === 201) {
            this.selected_deal.forEach((item) => {
              this.prop.int_props.splice(this.prop.int_props.indexOf(item), 1);
            });

            this.selected_deal = [];
          } else {
            this.error = true;
            this.errorMessage = "Something went wrong. Please try again.";
          }
        } catch (error) {
          console.log(error);
          this.error = true;
          this.errorMessage = error.message;
        }
      } else {
        console.log("unassociating canceled");
        this.selected_deal = [];
      }
    },

    async read_property() {
      try {
        const response = await API().get(
          `api/internal-admin/property/read_property?prop_id=${this.id}`
        );

        console.log("read property: ", response);

        const prop = response.data;

        if (response.status === 200) {
          this.prop = prop;
          this.loading = false;
        } else {
          this.loading = false;
        }
      } catch (error) {
        console.log(error);

        this.error = true;
        this.errorMessage = error.message;
        this.loading = false;

        setTimeout(() => {
          this.error = false;
          this.errorMessage = "";
        }, 5000);
      }
    },
  },

  filters: {
    ...filt,
  },

  created() {
    this.read_property();
    this.read_all_customer();
  },
};
</script>

<style>
.bordered {
  border: 1px solid #3c3c3c !important;
  background-color: transparent !important;
}
</style>
